import { Link, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import Calendar from '../../types/Calendar';
import Toast from '../common/Toast';
import { getCalendarSettings, updateCalendar, getLocationCalendarsForCalendar, updateLocationCalendar } from './api';

import './index.css';
import LocationCalendar from '../../types/LocationCalendar';


export default function CalendarSettings() {
  const { calendarSid, proctorAccountSid } = useParams();
  const [ calendar, setCalendar ] = useState<Calendar>()
  const [ showToast, setShowToast ] = useState<Boolean>(false);
  const [ toastMessage, setToastMessage ] = useState<string>("");
  const [ locationCalendars, setLocationCalendars ] = useState<LocationCalendar[]>([]);

  const handleCalendar = useCallback(async () => {
    setCalendar(await getCalendarSettings(calendarSid || ""));
  }, [calendarSid]);

  const handleLocationCalendars = useCallback(async () => {
    setLocationCalendars(await getLocationCalendarsForCalendar(calendarSid || ""));
  }, [calendarSid]);
  
  useEffect(() => {
    handleCalendar();
    handleLocationCalendars();
  }, [handleCalendar, handleLocationCalendars])

  const handleCloseToast = () => {
    setShowToast(false);
  }

  const handleCalendarSubmit = async (event: { preventDefault: () => void; target: any; }) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const sid = calendar?.sid || "";
    const name = formData.get("calendarName")?.toString() || "";
    const calendarUpdateCount = await updateCalendar({ sid: sid, name: name});

    if (calendarUpdateCount > 0) {
      setToastMessage("Calendar update successful.");
    } else {
      setToastMessage("Calendar update failed, please try again.");
    }
    setShowToast(true);
    await handleCalendar();
  }

  const handleLocationCalendarSubmit = async (event: { preventDefault: () => void; target: any; }) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    
    const locationCalendar: LocationCalendar = {
      proctorLocationSid: formData.get("proctorLocationSid")?.toString() || "",
      useSeats: formData.get("useSeats") === "on",
      numSeats: +(formData.get("numSeats")?.toString() || "0"),
      preventSchedulingWithin24Hours: formData.get("preventSchedulingWithin24Hours") === "on",
      calendar: {
        sid: formData.get("calendarSid")?.toString() || "",
        name: calendar?.name || ""
      }
    }

    const locationUpdateCount = await updateLocationCalendar(locationCalendar);

    if (locationUpdateCount > 0) {
      setToastMessage("Location update successful.");
    } else {
      setToastMessage("Location update failed, please try again.");
    }
    setShowToast(true);
    await handleLocationCalendars();
  }

  const renderProctorLocationCalendarSettings = () : JSX.Element[] => {
    let locationCalendarElements: JSX.Element[] = [];

    locationCalendars.map( (locationCalendar, index) => {
      locationCalendarElements.push(
        <form className="location-calendar" key={index} onSubmit={handleLocationCalendarSubmit}>
          <h4>Settings for Proctor Location {locationCalendar.proctorLocationSid}</h4>
          <label>
            <h5>Use assigned seating:</h5>
            <input type="checkbox" name="useSeats" defaultChecked={locationCalendar.useSeats} />
          </label>
          <label>
            <h5>Number of assigned seats:</h5>
            <input type="number" name="numSeats" defaultValue={locationCalendar.numSeats} />
          </label>
          <label>
            <h5>Prevent Scheduling within 24 Hours:</h5>
            <input type="checkbox" name="preventSchedulingWithin24Hours" defaultChecked={locationCalendar.preventSchedulingWithin24Hours} />
          </label>
          <input type="hidden" name="proctorLocationSid" value={locationCalendar.proctorLocationSid} />
          <input type="hidden" name="calendarSid" value={locationCalendar.calendar.sid} />
          <button type="submit">Save Location Settings</button>
        </form>
      );

      return locationCalendar;
    });

    return locationCalendarElements;
  }

  if (!calendarSid) return null;

  return (
    <>
      { proctorAccountSid &&
        <Link to={`/${proctorAccountSid}/calendars`} className="back-button"><button className="back">Back</button></Link>
      }
      { calendar &&
        <div className="calendar-form">
          <h2>Calendar Editor</h2>
          <form onSubmit={handleCalendarSubmit}>
            <div className="form-content">
              <label>
                <h5>Calendar Name:</h5>
                <input type="text" name="calendarName" defaultValue={calendar.name} />
              </label>
              <button type="submit">Save Calendar Settings</button>
            </div>
          </form>
        {
          locationCalendars.length > 0 &&
          <div className="proctor-location-settings">
            <h3>Proctor Location Settings</h3>
            { renderProctorLocationCalendarSettings() }
          </div>
        }
        </div>
      }
      
      { showToast && <Toast message={toastMessage} duration={3000} onClose={handleCloseToast} /> }
    </>
  )
}

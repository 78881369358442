import axios from 'axios';

import Calendar from "../../types/Calendar";
import { config } from '../../config';

export const getCalendarWithSchedules = async (calendarSid: String, month: number, year: number) : Promise<Calendar> => {
  const startDate = new Date(year, month, 1).toDateString();
  const endDate = new Date(year,month + 1, 0).toDateString();
  const response = await axios.get(`${config.apiUrl}/calendar/${calendarSid}/schedules?startDate=${startDate}&endDate=${endDate}`);
  return response.data;
}
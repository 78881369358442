import CloseButton from '../CloseButton';
import './index.css';

type Props = {
  cancelFunction: () => void;
  confirmFunction: () => void;
  message: string;
}

export default function ConfirmDialog(props: Props) {
  const { cancelFunction, confirmFunction, message } = props;

  return (
    <section className="confirm-dialog-wrapper">
      <div className="dialog">
        <CloseButton closeFunction={cancelFunction} />
        <p>{message}</p>
        <button className="confirm-button" onClick={confirmFunction}>Confirm</button>
      </div>
    </section>
  )
}
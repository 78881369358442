import { useCallback, useEffect, useState } from 'react';
import { getAppointmentExamDetails } from './api';
import './index.css';
import Exam from '../../types/Exam';
import Appointment from '../../types/Appointment';

type Props = {
  appointment: Appointment;
}

export default function AppointmentDetails(props: Props) {
  const { appointment } = props;
  const [ exam, setExam ] = useState<Exam>();
  const [ showPermittedItemsTitle, setShowPermittedItemsTitle] = useState<boolean>(false);

  const getAppointmentDetails = useCallback(async () => {
    let examDetails = await getAppointmentExamDetails(appointment.sid);
    setExam(examDetails);
    if (
      !exam?.permittedItems.bathroomBreak.allowed &&
      !exam?.permittedItems.blankPaper.allowed &&
      !exam?.permittedItems.calculator.allowed &&
      !exam?.permittedItems.dictionary.allowed &&
      !exam?.permittedItems.earplugs.allowed &&
      !exam?.permittedItems.formulaSheet.allowed &&
      !exam?.permittedItems.notes.allowed &&
      !exam?.permittedItems.textbook.allowed &&
      !exam?.permittedItems.websites.allowed
    ) {
      setShowPermittedItemsTitle(false)
    } else {
      setShowPermittedItemsTitle(true);
    }
  }, [appointment, exam?.permittedItems]);

  useEffect(() => {
    getAppointmentDetails();
  }, [getAppointmentDetails])

  return (<>
    <div className="appointment-details">
      <h2>Appointment Details</h2>
      <p><strong>Student Name:</strong> { appointment.studentFirstName + ' ' + appointment.studentLastName }</p>
      <p><strong>Duration:</strong> { appointment.durationMinutes } Minutes</p>
      <h3>Exam Details</h3>
      <p><strong>Exam Title:</strong> {exam?.title}</p>
      { exam?.description && <p>Description: {exam?.description}</p> }
      <p><strong>Exam Url:</strong> {exam?.url}</p>
      <p><strong>Exam Password:</strong> {exam?.password}</p>
      { exam?.studentNotes && <p><strong>Student Notes:</strong> {exam?.studentNotes}</p> }
      { exam?.proctorNotes && <p><strong>Proctor Notes:</strong> {exam?.proctorNotes}</p> }
      { ( showPermittedItemsTitle && <h5>Permitted Items</h5> ) || <h5>No permitted items for this exam.</h5> }
      <ul>
        {exam?.permittedItems.bathroomBreak.allowed &&
          <>
            <li>Bathroom Break</li>
            { exam?.permittedItems.bathroomBreak.info &&
              <ul>
                <li>
                  { exam?.permittedItems.bathroomBreak.info }
                </li>
              </ul>
            }
          </>
        }
        { exam?.permittedItems.blankPaper.allowed &&
          <>
            <li>Blank Paper</li>
            { exam?.permittedItems.blankPaper.info &&
              <ul>
                <li>
                  { exam?.permittedItems.blankPaper.info }
                </li>
              </ul>
            }
          </>
        }
        { exam?.permittedItems.calculator.allowed &&
          <>
            <li>Calculator</li>
            { exam?.permittedItems.calculator.info &&
              <ul>
                <li>
                  { exam?.permittedItems.calculator.info }
                </li>
              </ul>
            }
          </>
        }
        { exam?.permittedItems.dictionary.allowed &&
          <>
            <li>Dictionary</li>
            { exam?.permittedItems.dictionary.info &&
              <ul>
                <li>
                  { exam?.permittedItems.dictionary.info }
                </li>
              </ul>
            }
          </>
        }
        { exam?.permittedItems.earplugs.allowed &&
          <>
            <li>Earplugs</li>
            { exam?.permittedItems.earplugs.info &&
              <ul>
                <li>
                  { exam?.permittedItems.earplugs.info }
                </li>
              </ul>
            }
          </>
        }
        { exam?.permittedItems.formulaSheet.allowed &&
          <>
            <li>Formula Sheet</li>
            { exam?.permittedItems.formulaSheet.info &&
              <ul>
                <li>
                  { exam?.permittedItems.formulaSheet.info }
                </li>
              </ul>
            }
          </>
        }
        { exam?.permittedItems.notes.allowed &&
          <>
            <li>Notes</li>
            { exam?.permittedItems.notes.info &&
              <ul>
                <li>
                  { exam?.permittedItems.notes.info }
                </li>
              </ul>
            }
          </>
        }
        { exam?.permittedItems.textbook.allowed &&
          <>
            <li>Textbook</li>
            { exam?.permittedItems.textbook.info &&
              <ul>
                <li>
                  { exam?.permittedItems.textbook.info }
                </li>
              </ul>
            }
          </>
        }
        { exam?.permittedItems.websites.allowed &&
          <>
            <li>Websites</li>
            { exam?.permittedItems.websites.info &&
              <ul>
                <li>
                  { exam?.permittedItems.websites.info }
                </li>
              </ul>
            }
          </>
        }
        
      </ul>
    </div>
  </>);
}
import { useState } from 'react';
import Schedule from '../../types/Schedule';
import {updateSchedule } from './api'
import './index.css';

type Props = {
  schedule: Schedule
  updateCallback: (successful: boolean) => void
}

export default function ScheduleEditor(props: Props) {
  const { updateCallback } = props;
  const [ schedule, setSchedule] = useState(props.schedule);
  const [ testerArrivalManagementChecked, setTesterArrivalManagementChecked ] = useState<boolean>(schedule.startIntervalStudentLimit !== -1);
  const [ capacity, setCapacity ] = useState<number>(schedule.capacity);
  const [ appointmentIntervalMinutes, setAppointmentIntervalMinutes ] = useState<number>(schedule.appointmentIntervalMinutes);
  const [ bufferAfterAppointmentMinutes, setBufferAfterAppointmentMinutes] = useState<number>(schedule.bufferAfterAppointmentMinutes);
  const [ startIntervalStudentLimit, setStartIntervalStudentLimit ] = useState<number>(schedule.startIntervalStudentLimit);


  const startTimeParts = new Date(schedule.startTime).toTimeString().split(':');
  const endTimeParts = new Date(schedule.endTime).toTimeString().split(':');

  const handleSubmit = async (event: { preventDefault: () => void; target: any; }) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    const updatedSchedule : Schedule = {
      sid: schedule.sid,
      scheduleDate: new Date(schedule.scheduleDate),
      startTime: formatUTCDate(new Date(schedule.scheduleDate), formData.get('startTime')?.toString()),
      endTime: formatUTCDate(new Date(schedule.scheduleDate), formData.get('endTime')?.toString()),
      capacity: +(formData.get('capacity')?.toString() || "0"),
      appointmentIntervalMinutes: +(formData.get('appointmentIntervalMinutes')?.toString() || "0"),
      bufferAfterAppointmentMinutes: +(formData.get('bufferAfterAppointmentMinutes')?.toString() || "0"),
      startIntervalStudentLimit: -1,
      calendar: schedule.calendar
    }

    if(formData.get('testerArrivalManagement') === "on") {
      updatedSchedule.startIntervalStudentLimit = +(formData.get('startIntervalStudentLimit')?.toString() || "0");
    }

    const updated = await updateSchedule(updatedSchedule);

    if (updated > 0) {
      console.log('Schedule Updated Successfully');
      setSchedule(updatedSchedule);
      updateCallback(true);
    } else {
      console.log('Update Failed');
      updateCallback(false);
    }

    
  }

  const formatUTCDate = (date: Date, timeString: string | undefined) : Date => {
    const time = timeString || "00:00";
    const [ hours, minutes] : string[] = time.split(':');
    const newDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), +hours, +minutes);
    return newDate;
  }

  const handleTesterArrivalManagement = () => {
    if (!testerArrivalManagementChecked) {
      setStartIntervalStudentLimit(0);
    } else {
      setStartIntervalStudentLimit(-1);
    }
    setTesterArrivalManagementChecked(!testerArrivalManagementChecked);
  }

  return (
    <>
      <p className="sid">{schedule.sid}</p>
      <form onSubmit={handleSubmit} className="schedule-form">
        <div className="flex-container">
          <label className="start-time"><h5>Start Time:</h5><input type="time" name="startTime" defaultValue={startTimeParts[0] + ':' + startTimeParts[1]} /></label>
          <label className="end-time"><h5>End Time:</h5><input type="time" name="endTime" defaultValue={endTimeParts[0] + ':' + endTimeParts[1]} /></label>
        </div>
        <label><h5>Capacity:</h5>
          <div className="flex-container">
            <input type="number" name="capacity" defaultValue={schedule.capacity} onChange={(event) => {setCapacity(+event.target.value)}} />
            <p>No more than {capacity} testers testing at a given time.</p>
          </div>
        </label>
        <label><h5>Appointment Interval:</h5>
          <div className="flex-container">
            <input type="number" name="appointmentIntervalMinutes" defaultValue={schedule.appointmentIntervalMinutes} onChange={(event) => {setAppointmentIntervalMinutes(+event.target.value)}} />
            <p>Appointment start times offered every {appointmentIntervalMinutes} minutes.</p>
          </div>
        </label>
        <label><h5>Buffer Time:</h5>
          <div className="flex-container">
            <input type="number" name="bufferAfterAppointmentMinutes" defaultValue={schedule.bufferAfterAppointmentMinutes} onChange={(event) => {setBufferAfterAppointmentMinutes(+event.target.value)}}/>
            <p>A buffer of {bufferAfterAppointmentMinutes} minutes between the end of an appointment and the start of the next.</p>
          </div>
        </label>

        <label><h5>Tester Arrival Management:</h5>
          <div className="flex-container">
            <input type="checkbox" name="testerArrivalManagement" checked={testerArrivalManagementChecked} onChange={handleTesterArrivalManagement} />
          </div>
          { testerArrivalManagementChecked &&
            <div className="flex-container">
              <input type="number" name="startIntervalStudentLimit" value={startIntervalStudentLimit} onChange={(event) => {setStartIntervalStudentLimit(+event.target.value)}} />
              { 
                <p>Limit the number of testers who can start proctoring at a given appointment time to {startIntervalStudentLimit}</p> 
              }
            </div>
          }
        </label>
        <button type="submit">Save</button>
      </form>
    </>
  )
}
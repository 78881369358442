import { useCallback, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

import { deleteCalendar, getCalendarsFromProctorAccount, createCalendar, getProctorLocationsFromCalendarSids } from './api';
import Calendar from '../../types/Calendar';

import './index.css';
import Toast from '../common/Toast';
import ConfirmDialog from '../common/ConfirmDialog';
import LocationCalendar from '../../types/LocationCalendar';

export default function CalendarList() {
  const { proctorAccountSid } = useParams();
  const [ calendars, setCalendars ] = useState<Calendar[]>([]);
  const [ locationCalendars, setLocationCalendars ] = useState<LocationCalendar[]>([]);
  const [ showToast, setShowToast ] = useState<Boolean>(false);
  const [ toastMessage, setToastMessage ] = useState<string>("");
  const [ showConfirmDialog, setShowConfirmDialog ] = useState<Boolean>(false);
  const [ selectedCalendar, setSelectedCalendar ] = useState<string>("");
  const [ showLocations, setShowLocations ] = useState<boolean>(false);

  const getLocationCalendarsApiCall = useCallback(async () => {
    if (calendars.length > 0) {
      const calendarSids = calendars.map(calendar => calendar.sid);
      setLocationCalendars(await getProctorLocationsFromCalendarSids(calendarSids));
    }
  }, [calendars]);
  
  const getCalendarsApiCall = useCallback(async () => {
    setCalendars(await getCalendarsFromProctorAccount(proctorAccountSid || ""));
  }, [proctorAccountSid]);

  useEffect(() => {
    getCalendarsApiCall();
  }, [getCalendarsApiCall]);

  useEffect(() => {
    getLocationCalendarsApiCall();
  }, [getLocationCalendarsApiCall]);

  useEffect(() => {
    if (locationCalendars.find(locationCalendar => locationCalendar.useSeats)) {
      setShowLocations(true);
    } 
  }, [locationCalendars]);

  const handleCloseToast = () => {
    setShowToast(false);
  }

  const handleDeleteCalendar = async () => {
    const deletedCalendarCount = await deleteCalendar(selectedCalendar);
    if (deletedCalendarCount > 0) {
      setToastMessage("Calendar deleted successfully.");
    } else {
      setToastMessage("There was a problem deleting the calendar, please try again.");
    }
    setShowToast(true);
    await getCalendarsApiCall();
    closeDeleteDialog();
  }

  const closeDeleteDialog = () => {
    setShowConfirmDialog(false);
  }

  const handleCreateCalendar = async () => {
    if (proctorAccountSid) {
      const calendarSid: string = await createCalendar(proctorAccountSid);
    
      if (calendarSid && calendarSid.includes('CA')) {
        setToastMessage("Calendar created successfully.");
      } else {
        setToastMessage("There was a problem creating the calendar, please try again.");
      }
    } else {
      setToastMessage("Missing Proctor Account Sid, this shouldn't be possible, please contact SmarterServices Engineering.")
    }

    setShowToast(true);
    await getCalendarsApiCall();
  }

  const renderCalendars = () : JSX.Element[] => {
    const calendarElements: JSX.Element[] = [];
    
    calendars.sort((a: Calendar, b: Calendar) : number => {
      if (a.name > b.name) {
        return 1;
      } else if (a.name === b.name) {
        return 0;
      } else {
        return -1;
      }
    }).map( (calendar: Calendar, index) => {
      calendarElements.push(
        <div className="calendar" key={index}>
          <h3>{ calendar.name }</h3>
          <div className="button-wrapper">
            <Link to={`/${proctorAccountSid}/calendar/${calendar.sid}`}><button className="edit">Edit</button></Link>
            <Link to={`/${proctorAccountSid}/calendar/${calendar.sid}/settings`}><button className="settings">Settings</button></Link>
            <button className="delete" onClick={() => {
              setSelectedCalendar(calendar.sid);
              setShowConfirmDialog(true);
            }}>Delete</button>
          </div>
        </div>
      );

      return calendar;
    });

    return calendarElements;
  }

  const renderLocations = () : JSX.Element[] => {
    const locationElements: JSX.Element[] = [];
    locationCalendars.forEach((locationCalendar, index) => {
      if (locationCalendar.useSeats) {
        locationElements.push(
          <div className="proctor-location" key={index}>
            <h3>{ locationCalendar.proctorLocationName || locationCalendar.proctorLocationSid }</h3>
            <div className="button-wrapper">
              <Link to={`/${proctorAccountSid}/${locationCalendar.proctorLocationSid}/seats`}><button className="edit">Seat Visualizer</button></Link>
            </div>
          </div>
        );
      }
    })
    return locationElements;
  }

  if (!proctorAccountSid) return null;

  return (
    <>
      <div className="calendar-list">
        <div className="calendar-list-header">
          <h2>Calendars</h2>
          <button className="createCalendarButton" onClick={handleCreateCalendar}>Create</button>
        </div>
        { renderCalendars() } 
      </div>
      { showLocations &&
        <div className="proctor-location-list">
          <div className="proctor-location-list-header">
            <h2>Locations</h2>
          </div>
          { renderLocations() }
        </div>
      }
      { showToast && <Toast message={toastMessage} duration={3000} onClose={handleCloseToast} /> }
      { showConfirmDialog && <ConfirmDialog message="Are you sure you want to delete this calendar?" cancelFunction={closeDeleteDialog} confirmFunction={handleDeleteCalendar} /> } 
    </>
  );
}
import { useCallback, useEffect, useState } from "react";
import Seat from "../../types/Seat";
import { useParams } from "react-router-dom";
import { getSeatsForProctorLocation, createSeat, deleteSeat, getAppointmentsForSeat, updateSeat } from "./api";

import "./index.css";
import Appointment from "../../types/Appointment";
import CreateIcon from "../common/CreateIcon";
import Toast from "../common/Toast";
import ConfirmDialog from "../common/ConfirmDialog";
import DialogBox from "../common/DialogBox";
import SeatEditor from "../SeatEditor";
import AppointmentDetails from "../AppointmentDetails";
import { Link } from "react-router-dom";

export default function SeatVisualizer() {
  const { proctorAccountSid, proctorLocationSid } = useParams();
  const [ seats, setSeats ] = useState<Seat[]>([]);
  const [ date, setDate ] = useState<Date>(new Date((new Date()).toDateString() + ' 00:00:00'));
  const [ showToast, setShowToast ] = useState<boolean>(false);
  const [ toastMessage, setToastMessage ] = useState<string>("");
  const [ selectedSeat, setSelectedSeat ] = useState<string>("");
  const [ selectedAppointment, setSelectedAppointment ] = useState<Appointment>({
    sid: '',
    startTime: new Date(),
    durationMinutes: 0,
    sessionSid: '',
    appointmentType: 'standard'
  });
  const [ showConfirmDialog, setShowConfirmDialog ] = useState<boolean>(false);
  const [ showEditDialog, setShowEditDialog ] = useState<boolean>(false);
  const [ showAppointmentDetails, setShowAppointmentDetails ] = useState<boolean>(false)
  
  const getSeats = useCallback(async () => {
    let seats = await getSeatsForProctorLocation(proctorLocationSid || "");
    seats = await Promise.all(seats.map( async (seat) => {
      seat.appointments = await getAppointmentsForSeat(seat.sid, date);
      return seat;
    }));
    setSeats(seats);
  }, [proctorLocationSid, date]);

  useEffect(() => {
    getSeats();
  }, [getSeats]);

  const handleCloseToast = () => {
    setShowToast(false);
  }

  const handleCreateSeat = async () => {
    if (proctorLocationSid) {
      const seatSid: string = await createSeat(proctorLocationSid);
      if (seatSid.startsWith("SE")) {
        setToastMessage("Seat Created Successfully.");
      } else {
        setToastMessage("Could not create a seat, please try again or contact SmarterServices Support.");
      }
      setShowToast(true);
    }
    getSeats();
  }

  const handleUpdateSeat = async (seat: Seat) => {
    const updatedSeats: number = await updateSeat(seat);
    if (updatedSeats > 0) {
      setToastMessage("Seat Updated Successfully.");
    } else {
      setToastMessage("Could not update this seat, please try again or contact SmarterServices Support.");
    }
    setShowToast(true);
    getSeats();
    closeEditDialog();
  }

  const handleDeleteSeat = async () => {
    const numDeleted = await deleteSeat(selectedSeat);
    if (numDeleted > 0) {
      setToastMessage("Seat Deleted Successfully.");
    } else {
      setToastMessage("Couldn't delete this seat, please try again or contact SmarterServices Support.");
    }
    setShowConfirmDialog(false);
    getSeats();
  }

  const closeDeleteDialog = () => {
    setSelectedSeat("");
    setShowConfirmDialog(false);
  }

  const closeEditDialog = () => {
    setSelectedSeat("");
    setShowEditDialog(false);
  }

  const handleShowAppointmentDetails = (appointment: Appointment) => {
    setSelectedAppointment(appointment);
    setShowAppointmentDetails(true);
  }

  const closeAppointmentDetails = () => {
    setShowAppointmentDetails(false);
  }

  const renderSeats = () : JSX.Element[] => {
    const seatsElements: JSX.Element[] = [];
    seats.forEach( (seat, index) => {
      seatsElements.push(
        <div className="seat" key={index}>
          <h4>{seat.name}</h4>
          <div className="edit-button">
            <div className="edit-icon" onClick={() => {
              setSelectedSeat(seat.sid);
              setShowEditDialog(true);
            }}></div>
          </div>
          <div className="delete-button">
            <div className="delete-icon" onClick={() => {
              setSelectedSeat(seat.sid);
              setShowConfirmDialog(true);
            }}></div>
          </div>
        </div>
      );
    });
    return seatsElements;
  };

  const renderAppointmentsForTimeline = (appointments: Appointment[]): JSX.Element[] => {
    const appointmentElements: JSX.Element[] = [];
    appointments.forEach( (appointment, index) => {
      appointment.startTime = new Date(appointment.startTime);
      appointmentElements.push(
        <div style={{ width: (appointment.durationMinutes * 2) + 'px', left: ((appointment.startTime.getHours() * 60 + appointment.startTime.getMinutes()) * 2) + 'px'}} className="appointment" key={index} onClick={() => handleShowAppointmentDetails(appointment)}>
          { appointment.studentFirstName && appointment.studentLastName && appointment.examTitle &&
            <>
              <h5>{appointment.studentFirstName + ' ' + appointment.studentLastName}</h5>
              <h6>{appointment.examTitle}</h6>
            </>
          }
        </div>
      );
    });
    return appointmentElements;
  };

  const renderSeatTimelines = () : JSX.Element[] => {
    const timelineElements: JSX.Element[] = [];
    seats.forEach((seat, index) => {
      timelineElements.push(
        <div className="timeline" key={index}>
          { renderAppointmentsForTimeline(seat.appointments || []) }
        </div>
      );
    })
    return timelineElements;
  };

  const renderHours = () : JSX.Element[] => {
    const hourElements = [];
    for (let i = 0; i < 24; i++) {
      let hour: number;
      let ampm: string;

      
      if (i === 0) {
        hour = 12;
        ampm = 'am';
      } else if (i < 12) {
        hour = i;
        ampm = 'am'
      } else if (i === 12) {
        hour = i;
        ampm = 'pm';
      } else {
        hour = i % 12;
        ampm = 'pm'
      }

      hourElements.push(
        <div className="hour-header" key={i}>
          <h5>{hour + ampm}</h5>
        </div>
      );
    }
    return hourElements;
  };
  
  return (
    <>
      { proctorAccountSid &&
        <Link to={`/${proctorAccountSid}/calendars`} className="back-button"><button className="back">Back</button></Link>
      }
      <section className="seat-visualizer">
        <div>
        <div className="seat-sidebar">
          <div className="seat-sidebar-header">
            <div className="date-selector-wrapper">
              <input type="date" defaultValue={date.toISOString().split('T')[0]} onChange={(e: React.FormEvent<HTMLInputElement>) => { setDate(new Date(e.currentTarget.value + ' 00:00:00')) }}/>
            </div>
          </div>
          {renderSeats()}
          <div className="seat seat-create" onClick={handleCreateSeat}>
            <CreateIcon />
            <h4>Create Seat</h4>
          </div>
        </div>
        <div className="seat-appointments">
          <div className="seat-appointments-header">
            {renderHours()}
          </div>
          {renderSeatTimelines()}
        </div>
        </div>
      </section>
      { showToast && 
        <Toast message={toastMessage} duration={3000} onClose={handleCloseToast} /> 
      }
      { showConfirmDialog && 
        <ConfirmDialog message="Are you sure you want to delete this seat?" cancelFunction={closeDeleteDialog} confirmFunction={handleDeleteSeat} /> 
      }
      { showEditDialog &&
        <DialogBox closeDialog={closeEditDialog} component={<SeatEditor seat={seats.find(seat => { return seat.sid === selectedSeat;}) || {sid: '', name: '', proctorLocationSid: ''}} updateFunction={handleUpdateSeat} />} />
      }
      {
        showAppointmentDetails &&
        <DialogBox closeDialog={closeAppointmentDetails} component={<AppointmentDetails appointment={selectedAppointment} />} />
      }
    </>
  );
}
import { useEffect, useState } from 'react';

import './index.css';
import { getSchedulesForDay, createSchedule, deleteSchedule } from './api';
import Schedule from '../../types/Schedule';
import ScheduleEditor from '../ScheduleEditor';
import Toast from '../common/Toast';
import ConfirmDialog from '../common/ConfirmDialog';

type Props = {
  calendarSid: string;
  month: number;
  year: number;
  day: number;
}

export default function CalendarDayEditor(props: Props) {
  const { calendarSid, month, day, year } = props;
  const [ schedules, setSchedules ] = useState<Schedule[]>([]);
  const [ selectedSchedule, setSelectedSchedule ] = useState(-1);
  const [ updatedSchedule, setUpdatedSchedule ] = useState(0); // This is to force a re-pull of data when the user saves a schedule
  const [ showToast, setShowToast ] = useState(false);
  const [ toastMessage, setToastMessage] = useState('');
  const [ showConfirm, setShowConfirm ] = useState(false);

  useEffect(() => {
    const apiCall = async () => {
      const calendar = await getSchedulesForDay(calendarSid, day, month, year);
      setSchedules(calendar.schedules || []);
    }

    apiCall();
  }, [calendarSid, month, year, day, updatedSchedule]);

  const handleCloseToast = () => {
    setShowToast(false);
  }

  const showDeleteConfirmationBox = () => {
    setShowConfirm(true);
  }

  const closeDeleteDialog = () => {
    setShowConfirm(false);
  }

  const setSelectedOption = (option: number) => {
    setSelectedSchedule(option);
  };

  const scheduleUpdate = (successful: boolean) => {
    setUpdatedSchedule(updatedSchedule + 1);
    
    if (successful) {
      setToastMessage('Schedule updated successfully.')
      setShowToast(true);
    } else {
      setToastMessage('Schedule failed to update, please refresh and try again.')
      setShowToast(true);
    }
  };

  const handleNewSchedule = async () => {
    const scheduleDate = year + '-' + (month < 9 ? '0' + (month + 1) : month + 1) + '-' + (day < 10 ? '0' + day : day)
    const scheduleJson = {
      calendarSid: calendarSid,
      scheduleDate: scheduleDate,
      startTime: new Date(year, month, day, 0, 0, 0).toUTCString(),
      endTime: new Date(year, month, day, 23, 59, 59).toUTCString(),
      capacity: 0,
      appointmentIntervalMinutes: 15,
      bufferAfterAppointmentMinutes: 30,
      startIntervalStudentLimit: -1
    };

    const sid = await createSchedule(scheduleJson);
    const newSchedule: Schedule = {
      sid: sid,
      scheduleDate: new Date(scheduleJson.scheduleDate + " 00:00:00+00"),
      startTime: new Date(year, month, day, 0, 0, 0),
      endTime: new Date(year, month, day, 23, 59, 59),
      capacity: 0,
      appointmentIntervalMinutes: 15,
      bufferAfterAppointmentMinutes: 30,
      startIntervalStudentLimit: -1,
      calendar: {
        sid: calendarSid,
        name: ''
      }
    }

    setSchedules(schedules => [ ...schedules, newSchedule ])
  };

  const handleDeleteSchedule = async () => {
    if (selectedSchedule > -1 && selectedSchedule < schedules.length) {
      const scheduleSid = schedules[selectedSchedule].sid;
      const numDeleted = await deleteSchedule(scheduleSid);

      if (numDeleted > 0) {
        setSchedules(schedules.filter((schedule) => {
          return schedule.sid !== scheduleSid;
        }));
        setSelectedSchedule(-1);
        setToastMessage("Schedule Deleted Successfully.")
      } else {
        setToastMessage("There was a problem trying to delete the schedule, please try again.")
      }
    } else {
      setToastMessage("There was a problem trying to delete the schedule, please try again.")
    }
    setShowConfirm(false);
    setShowToast(true);
  }

  const renderSchedulePickerOptions = () : JSX.Element[] => {
    const options : JSX.Element[] = [];

    schedules.sort((a : Schedule, b: Schedule) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime()).map((schedule : Schedule, index) => {
      const classes : string = selectedSchedule === index ? "option selected" : "option";
      options.push(
        <div className={classes} key={index} onClick={() => setSelectedOption(index)}>
          <p>{new Date(schedule.startTime).toLocaleTimeString([], { timeStyle: 'short' })} - {new Date(schedule.endTime).toLocaleTimeString([], { timeStyle: 'short' })} <br /><span>Capacity: {schedule.capacity}</span></p>
          <div className="delete-button" onClick={() => showDeleteConfirmationBox()}>
            <div className="delete-icon"></div>
          </div>
        </div>
      );
      return schedule;
    });

    options.push(
      <div className="create-schedule-button option" key={schedules.length} onClick={() => handleNewSchedule()}>
        <h3>Create Availability</h3>
      </div>
    )

    return options;
  };

  return (
    <>
      <div className="calendar-day-top-bar">
        <h3>Availability for {month < 9 ? '0' + (month + 1) : month + 1}/{day < 10 ? '0' + day : day}/{year}</h3>
      </div>
      <div className="calendar-day-editor">
        <div className="schedule-picker-sidebar">
          { renderSchedulePickerOptions() }
        </div>
        <div className="schedule-picker-editor">
          { selectedSchedule > -1 && selectedSchedule < schedules.length && <ScheduleEditor key={selectedSchedule} schedule={schedules[selectedSchedule]} updateCallback={scheduleUpdate} /> }
        </div>
      </div>
      { showToast && <Toast message={toastMessage} duration={3000} onClose={handleCloseToast} /> }
      { showConfirm && <ConfirmDialog message="Are you sure you want to delete this schedule?" cancelFunction={closeDeleteDialog} confirmFunction={handleDeleteSchedule} /> } 
    </>
  );
}
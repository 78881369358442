import { useState } from "react";
import Seat from "../../types/Seat"
import Toast from "../common/Toast";

import './index.css';

type Props = {
  seat: Seat;
  updateFunction: (seat: Seat) => void;
}

export default function SeatEditor(props: Props) {
const { seat, updateFunction } = props;
const [ showToast, setShowToast ] = useState<Boolean>(false);
const [ toastMessage, setToastMessage ] = useState<string>("");

const handleCloseToast = () => {
  setShowToast(false);
}

const handleEdit = (event: { preventDefault: () => void; target: any; }) => {
  event.preventDefault();
  const form = event.target;
  const formData = new FormData(form);
  const seatName: string | undefined = formData.get("seat-name")?.toString();
  
  if (seatName) {
    seat.name = seatName;
    updateFunction(seat);
  } else {
    setToastMessage("Something went wrong, please try again later or contact SmarterServices Support.");
    setShowToast(true);
  }
};

  return (
    <div className="seat-editor-wrapper">
      <h2>Edit Seat</h2>
      <form className="seat-editor" onSubmit={handleEdit}>
          <label className="seat-name"><h5>Seat Name:</h5><input name="seat-name" defaultValue={seat.name} /></label>
          <button type="submit">Save</button>
      </form>
      { showToast && <Toast message={toastMessage} duration={3000} onClose={handleCloseToast} /> }
    </div>
  )
}
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CalendarWrapper from "./components/CalendarWrapper";
import CalendarList from './components/CalendarList';
import CalendarSettings from './components/CalendarSettings';
import SeatVisualizer from './components/SeatVisualizer';

function AppRouter() {
    return (
        <Router>
            <Routes>
                <Route path="/:proctorAccountSid/calendars" element={<CalendarList />} />
                <Route path="/:proctorAccountSid/calendar/:calendarSid/settings" element={<CalendarSettings />} />
                <Route path="/:proctorAccountSid/calendar/:calendarSid" element={<CalendarWrapper />} />
                <Route path="/:proctorAccountSid/:proctorLocationSid/seats" element={<SeatVisualizer />} />
            </Routes>
        </Router>
    );
}

export default AppRouter;
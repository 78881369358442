import axios from 'axios';

import { config } from '../../config';
import Calendar from '../../types/Calendar';
import LocationCalendar from '../../types/LocationCalendar';

export const getCalendarsFromProctorAccount = async (proctorAccountSid: string) : Promise<Calendar[]> => {
  const response = await axios.get(`${config.apiUrl}/calendar/${proctorAccountSid}/list`);
  return response.data;
}

export const getProctorLocationsFromCalendarSids = async (calendarSids: string[]) : Promise<LocationCalendar[]> => {
  const calendarSidsString = calendarSids.join(',');
  const response = await axios.get(`${config.apiUrl}/locationCalendar?calendarSids=${calendarSidsString}`);
  return response.data;
}

export const deleteCalendar = async (calendarSid: string) : Promise<number> => {
  const response = await axios.delete(`${config.apiUrl}/calendar/${calendarSid}`);
  return response.data;
}

export const createCalendar = async (proctorAccountSid: string) : Promise<string> => {
  const response = await axios.post(`${config.apiUrl}/calendar/create`, {
    name: 'New Calendar',
    proctorAccountSid: proctorAccountSid
  });
  return response.data;
}
import axios from 'axios';
import { config } from '../../config';
import Calendar from '../../types/Calendar';
import LocationCalendar from '../../types/LocationCalendar';

export const getCalendarSettings = async (calendarSid: string) : Promise<Calendar> => {
  const response = await axios.get(`${config.apiUrl}/calendar/${calendarSid}`);
  return response.data;
}

export const updateCalendar = async (calendar: Calendar) : Promise<number> => {
  const response = await axios.put(`${config.apiUrl}/calendar/${calendar.sid}`, {
    name: calendar.name
  });
  return response.data;
}

export const getLocationCalendarsForCalendar = async (calendarSid: string) : Promise<LocationCalendar[]> => {
  const response = await axios.get(`${config.apiUrl}/locationCalendar/calendar/${calendarSid}`);
  return response.data;
}

export const updateLocationCalendar = async (locationCalendar: LocationCalendar) : Promise<number> => {
  const response = await axios.put(`${config.apiUrl}/locationCalendar/location/${locationCalendar.proctorLocationSid}/calendar/${locationCalendar.calendar.sid}`,{
    useSeats: locationCalendar.useSeats,
    numSeats: locationCalendar.numSeats,
    preventSchedulingWithin24Hours: locationCalendar.preventSchedulingWithin24Hours
  });
  return response.data;
}
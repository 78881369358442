import { useState } from "react";

import { ScheduleData, ScheduleDataRequest } from "../../types/Schedule"
import BulkScheduleEditorForm from "../BulkScheduleEditorForm";
import { bulkCreateSchedules } from './api';

import './index.css';

type Props = {
  calendarSid: string;
  submitCallback: (success: boolean) => void;
}

export default function BulkScheduleEditor(props: Props) {
  const { calendarSid, submitCallback } = props;
  const [ selectedDay, setSelectedDay ] = useState<number>(0);
  const [ selectedOption, setSelectedOption ] = useState<number>(-1);
  const [ sundaySchedules, setSundaySchedules ] = useState<ScheduleData[]>([]);
  const [ mondaySchedules, setMondaySchedules ] = useState<ScheduleData[]>([]);
  const [ tuesdaySchedules, setTuesdaySchedules ] = useState<ScheduleData[]>([]);
  const [ wednesdaySchedules, setWednesdaySchedules ] = useState<ScheduleData[]>([]);
  const [ thursdaySchedules, setThursdaySchedules ] = useState<ScheduleData[]>([]);
  const [ fridaySchedules, setFridaySchedules ] = useState<ScheduleData[]>([]);
  const [ saturdaySchedules, setSaturdaySchedules ] = useState<ScheduleData[]>([]);
  const [ startDate, setStartDate ] = useState<Date>(new Date());
  const [ endDate, setEndDate ] = useState<Date>(new Date());

  const convertScheduleDataToRequest = (schedules: ScheduleData[]): ScheduleDataRequest[] => {
    const requests: ScheduleDataRequest[] = []
    schedules.forEach( (schedule) => {
      const request: ScheduleDataRequest = {
        capacity: schedule.capacity,
        appointmentIntervalMinutes: schedule.appointmentIntervalMinutes,
        bufferAfterAppointmentMinutes: schedule.bufferAfterAppointmentMinutes,
        startTime: schedule.startTime.toTimeString(),
        endTime: schedule.endTime.toTimeString(),
      }
      requests.push(request);
    });
    return requests;
  }

  const handleBulkCreateSchedules = async (event: { preventDefault: () => void; target: any; }) => {
    event.preventDefault();
    const data = {
      calendarSid: calendarSid,
      schedules: {
        "0": convertScheduleDataToRequest(sundaySchedules),
        "1": convertScheduleDataToRequest(mondaySchedules),
        "2": convertScheduleDataToRequest(tuesdaySchedules),
        "3": convertScheduleDataToRequest(wednesdaySchedules),
        "4": convertScheduleDataToRequest(thursdaySchedules),
        "5": convertScheduleDataToRequest(fridaySchedules),
        "6": convertScheduleDataToRequest(saturdaySchedules)
      },
      startDate: startDate.toISOString().split('T')[0],
      endDate: endDate.toISOString().split('T')[0]
    }

    const success = await bulkCreateSchedules(data);
    submitCallback(success);
  }

  const getSchedulesBySelectedDay = (): ScheduleData[] => {
    switch (selectedDay) {
      case 0: {
        return sundaySchedules;
      }
      case 1: {
        return mondaySchedules;
      }
      case 2: {
        return tuesdaySchedules;
      }
      case 3: {
        return wednesdaySchedules;
      }
      case 4: {
        return thursdaySchedules;
      }
      case 5: {
        return fridaySchedules;
      }
      case 6: {
        return saturdaySchedules;
      }
      default: {
        console.error(`selectedDay is invalid, must be an integer between 0 and 6 \r\n selectedDay is currently ${selectedDay}`);
        return [];
      }
    }
  }

  const setSchedulesBySelectedDay = (schedules: ScheduleData[]) => {
    switch (selectedDay) {
      case 0: {
        return setSundaySchedules(schedules);
      }
      case 1: {
        return setMondaySchedules(schedules);
      }
      case 2: {
        return setTuesdaySchedules(schedules);
      }
      case 3: {
        return setWednesdaySchedules(schedules);
      }
      case 4: {
        return setThursdaySchedules(schedules);
      }
      case 5: {
        return setFridaySchedules(schedules);
      }
      case 6: {
        return setSaturdaySchedules(schedules);
      }
      default: {
        console.error(`selectedDay is invalid, must be an integer between 0 and 6 \r\n selectedDay is currently ${selectedDay}`);
        return [];
      }
    }
  }

  const updateSchedules = (schedule: ScheduleData) => {
    const schedules = [...getSchedulesBySelectedDay()];
    schedules[selectedOption] = schedule;
    setSchedulesBySelectedDay(schedules);
  }

  const handleNewSchedule = () => {
    const startTime = new Date();
    const endTime = new Date();
    startTime.setHours(0,0,0,0);
    endTime.setHours(23,59,59,0);

    const schedules = getSchedulesBySelectedDay();
    setSchedulesBySelectedDay([...schedules, {
      capacity: 0,
      appointmentIntervalMinutes: 15,
      bufferAfterAppointmentMinutes: 30,
      startTime: startTime,
      endTime: endTime
    }])
  }

  const removeSchedule = (scheduleIndex: number) => {
    const schedules = [...getSchedulesBySelectedDay()];
    schedules.splice(scheduleIndex, 1);
    setSchedulesBySelectedDay(schedules);
    setSelectedOption(-1);
  };

  const renderScheduleDays = () : JSX.Element[] => {
    const daysOfWeek: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const days: JSX.Element[] = [];
    daysOfWeek.map((day, index) => {
      const classes = index === selectedDay ? 'day-of-week selected' : 'day-of-week';
      days.push(
        <div className={classes} key={index} onClick={() => {
          setSelectedDay(index);
          setSelectedOption(-1);
        }}>
          <h3>{day}</h3>
        </div>
      );
      return day;
    });
    return days;
  }

  const renderScheduleOptions = (schedules: ScheduleData[]) : JSX.Element[] => {
    const options : JSX.Element[] = [];

    if(schedules.length > 0) {
      schedules.sort((a : ScheduleData, b: ScheduleData) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime()).map((schedule : ScheduleData, index) => {
        const classes : string = selectedOption === index ? "option selected" : "option";

        options.push(
          <div className={classes} key={index}>
            <p onClick={() => setSelectedOption(index)}>{new Date(schedule.startTime).toLocaleTimeString([], { timeStyle: 'short' })} - {new Date(schedule.endTime).toLocaleTimeString([], { timeStyle: 'short' })} <br /><span>Capacity: {schedule.capacity}</span></p>
            <div className="delete-button" onClick={() => removeSchedule(index)}>
              <div className="delete-icon"></div>
            </div>
          </div>
        );
        return schedule;
      });
    }
  
    options.push(
      <div className="create-schedule-button option" key={schedules.length} onClick={handleNewSchedule}>
        <h3>Create Availability</h3>
      </div>
    )

    return options;
  };
  
  return (
    <>
      <h1 className="bulk-editor-title">Bulk Availability Editor</h1>
      <form onSubmit={handleBulkCreateSchedules} className="bulk-form">
        <div className="bulk-form-wrapper">
          <label>
            <h5>Start Date: </h5>
            <input type="date" defaultValue={startDate.toISOString().split('T')[0]} onChange={(e: React.FormEvent<HTMLInputElement>) => { setStartDate(new Date(e.currentTarget.value + 'T00:00:00')) }}/>
          </label>
          <label>
            <h5>End Date:</h5>
            <input type="date" defaultValue={endDate.toISOString().split('T')[0]} onChange={(e: React.FormEvent<HTMLInputElement>) => { setEndDate(new Date(e.currentTarget.value + 'T00:00:00')) }}/>
          </label>
          <button type="submit">Create Availabilities</button>
        </div>
      </form>
      <div className="bulk-editor-days">
        { renderScheduleDays() }
      </div>
      <div className="bulk-editor-wrapper">
        <div className="bulk-editor-sidebar">
          { renderScheduleOptions(getSchedulesBySelectedDay()) }
        </div>
        <div className="bulk-editor">
        { selectedOption > -1 && selectedOption < getSchedulesBySelectedDay().length && <BulkScheduleEditorForm key={selectedOption} schedule={getSchedulesBySelectedDay()[selectedOption]} updateCallback={updateSchedules} /> }
        </div>
      </div>
    </>
  );
}
import axios from 'axios'
import Calendar from '../../types/Calendar';
import { config } from '../../config';

export const getSchedulesForDay = async (calendarSid: string, day: number, month: number, year: number) : Promise<Calendar> => {
  const monthString = month < 9 ? '0' + (month + 1) : month + 1;
  const dayString = day < 10 ? '0' + day : day;
  const date : string = year + "-" + monthString + '-' + dayString;
  const response = await axios.get(`${config.apiUrl}/calendar/${calendarSid}/schedules?startDate=${date}&endDate=${date}`);
  return response.data;
}

type CreateScheduleRequest = {
  calendarSid: string,
  scheduleDate: string,
  startTime: string,
  endTime: string
  capacity: number,
  appointmentIntervalMinutes: number,
  bufferAfterAppointmentMinutes: number,
  startIntervalStudentLimit: number
};

export const createSchedule = async (request: CreateScheduleRequest) : Promise<string> => {
  const response = await axios.post(`${config.apiUrl}/schedule/create`, request);
  return response.data;
}

export const deleteSchedule = async (scheduleSid: string) : Promise<number> => {
  const response = await axios.delete(`${config.apiUrl}/schedule/${scheduleSid}`);
  return response.data;
}
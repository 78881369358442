import './index.css';

export default function CreateIcon() {
  return(
    <div className="create-button">
      <div className="cross-1">
        <div className="cross-2"></div>
      </div>
    </div>
  )
}
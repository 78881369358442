import { useState } from "react";
import { Link, useParams } from 'react-router-dom';

import Calendar from "../Calendar";

import './index.css';
import DialogBox from "../common/DialogBox";
import BulkScheduleEditor from "../BulkScheduleEditor";
import Toast from "../common/Toast";

type Props = {}

export default function CalendarWrapper(props: Props) {
  const [ month, setMonth ] = useState(new Date().getMonth());
  const [ year, setYear ] = useState(new Date().getFullYear());
  const [ showBulkEditor, setShowBulkEditor ] = useState(false);
  const [ showToast, setShowToast ] = useState<boolean>(false);
  const [ toastMessage, setToastMessage ] = useState<string>("");

  const { proctorAccountSid, calendarSid } = useParams();
  if (!calendarSid) return null; // or display an error message

  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const nextMonth = () => {
    let newMonth: number = month;
    let newYear: number = year;
    newMonth++;

    if (newMonth > 11) {
      newYear++;
      newMonth = newMonth % 12;
    }

    setMonth(newMonth);

    if (year !== newYear) {
      setYear(newYear);
    }
  }

  const previousMonth = () => {
    let newMonth: number = month;
    let newYear: number = year;
    newMonth--;

    if (newMonth < 0) {
      newYear--;
      newMonth = newMonth % 12;
    }

    setMonth(newMonth);

    if (year !== newYear) {
      setYear(newYear);
    }
  }

  const showBulkCreate = () => {
    setShowBulkEditor(true);
  }

  const handleCloseToast = () => {
    setShowToast(false);
  }

  const handleBulkCreate = (success: boolean) => {
    if (success) {
      setToastMessage("Bulk Availability creation successful.");
    } else {
      setToastMessage("Bulk Availability creation failed. Please contact SmarterService support.");
    }
    closeDialog();
  }

  const closeDialog = () => {
    setShowBulkEditor(false);
  }

  return(
    <>
      <div className="calendar-bar">
        { proctorAccountSid &&
          <Link to={`/${proctorAccountSid}/calendars`} className="back-button"><button className="back">Back to Calendars</button></Link>
        }
        <button className="previous" onClick={previousMonth}>Previous</button>
        <button className="next" onClick={nextMonth}>Next</button>
        <h3 className="calendar-title">{monthNames[month]} {year}</h3>
        <button className="bulk-create" onClick={showBulkCreate}>Bulk Availability Generator</button>
      </div>
      <Calendar calendarSid={calendarSid} month={month} year={year} />
      { showBulkEditor &&
        <DialogBox closeDialog={closeDialog} component={<BulkScheduleEditor calendarSid={calendarSid} submitCallback={handleBulkCreate} />} />
      }
      { showToast && <Toast message={toastMessage} duration={3000} onClose={handleCloseToast} /> }
    </>
  )

}
import Schedule from "../../types/Schedule";

import './index.css';

type Props = {
  day?: number;
  schedules?: Schedule[];
  key: number;
  classes: string;
  clickCallback?: () => void;
}

export default function CalendarDay(props: Props) {
  const { day, schedules, classes, clickCallback } = props;

  const renderSchedules = () : JSX.Element[] => {
    const scheduleList: JSX.Element[] = [];

    schedules?.sort( (a, b) : number => {
      if (a.endTime === b.endTime) {
        return 0;
      } else {
        return a.endTime > b.endTime ? 1 : -1
      }
    });

    schedules?.map( (schedule, index) => {
      const startTime = new Date(schedule.startTime).toLocaleTimeString([], { timeStyle: 'short' });
      const endTime = new Date(schedule.endTime).toLocaleTimeString([], { timeStyle: 'short' });
      const scheduleText = <div key={index} className="schedule-line"><p className="schedule">{startTime + ' - ' + endTime}</p><span>Capacity: {schedule.capacity}</span></div>;
      scheduleList.push(scheduleText);
      return schedule;
    })
    return scheduleList;
  }

  return(
    <td className={classes} onClick={clickCallback}>
      <h3 className="calendar-day">{day ? day : ''}</h3>
      <div className="schedules">
        { renderSchedules() }
      </div>
    </td>
  )
}
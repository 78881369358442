import axios from 'axios'
import Schedule from '../../types/Schedule'
import { config } from '../../config';

export const updateSchedule = async (schedule: Schedule) : Promise<number> => {
  
  const response = await axios.put(`${config.apiUrl}/schedule/${schedule.sid}`, {
    scheduleDate: schedule.scheduleDate.toUTCString(),
    startTime: schedule.startTime.toUTCString(),
    endTime: schedule.endTime.toUTCString(),
    capacity: schedule.capacity,
    appointmentIntervalMinutes: schedule.appointmentIntervalMinutes,
    bufferAfterAppointmentMinutes: schedule.bufferAfterAppointmentMinutes,
    calendarSid: schedule.calendar?.sid
  });

  return response.data;
}
import React, { useState, useEffect } from 'react';
import './index.css';

interface Props {
  message: string;
  duration?: number;
  onClose?: () => void;
}

export default function Toast(props: Props) {
  const { message, duration, onClose } = props
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(false);
      onClose && onClose();
    }, duration);

    return () => clearTimeout(timeout);
  }, [duration, onClose]);

  const handleClose = () => {
    setShow(false);
    onClose && onClose();
  };

  return (
    <>
      {show && (
        <div className="toast-container">
          <div className="toast-message">{message}</div>
          <button className="toast-close-button" onClick={handleClose}>
            &#x2715;
          </button>
        </div>
      )}
    </>
  );
};
import { useCallback, useEffect, useState } from 'react';

import './index.css';
import { getCalendarWithSchedules } from './api';
import Schedule from '../../types/Schedule';
import CalendarDayEditor from '../CalendarDayEditor';
import CalendarDay from "../CalendarDay";
import DialogBox from '../common/DialogBox';
// import BulkScheduleEditor from '../BulkScheduleEditor';

type Props = {
  month: number;
  year: number;
  calendarSid: string;
}

export default function Calendar(props: Props) {
  const { month, year, calendarSid } = props;
  const [ schedules, setSchedules ] = useState<{ [key: string]: Schedule[] } | null>();
  const [ selectedDay, setSelectedDay ] = useState(0);
  const [ showDayEditor, setShowDayEditor ] = useState(false);
  // const [ showBulkEditor, setShowBulkEditor ] = useState(false);

  const apiCall = useCallback(async () => {
    const calendar = await getCalendarWithSchedules(calendarSid, month, year);
    const scheduleDictionary: { [key: string]: Schedule[]} = {};
    calendar.schedules?.map(schedule => {
      const dayNumber: string = new Date(schedule.scheduleDate).getUTCDate().toString();

      if (scheduleDictionary[dayNumber]) {
        scheduleDictionary[dayNumber].push(schedule);
      } else {
        scheduleDictionary[dayNumber] = [ schedule ];
      }

      return schedule;
    });
    setSchedules(scheduleDictionary);
  }, [calendarSid, month, year]);

  useEffect(() => {
    setSelectedDay(0);
    apiCall();
  }, [month, year, apiCall]);

  useEffect(() => {
    apiCall();
  }, [showDayEditor, apiCall]);

  const selectDay = (day: number) => {
    setSelectedDay(day);
    setShowDayEditor(true);
  }

  const closeDialog = () => {
    setShowDayEditor(false);
  }

  const generateCalendar = (year: number, month: number) : JSX.Element => {
    let days : Array<string | JSX.Element> = [];
    const startDate: Date = new Date(year, month, 1);
    const startDateDayOfWeek = startDate.getDay();
    
    // Fill up days in calendar before start day of the week.
    for (let i = 0; i < startDateDayOfWeek; i++) {
      days.push(<CalendarDay key={days.length} classes={"day"}/>);
    }

    // Fill calendar with all days of the month
    const lastDayOfMonth = new Date(year, month + 1, 0).getDate(); // gets the last day of the month
    for (let i = 1; i <= lastDayOfMonth; i++) {
      let classes = (i === selectedDay) ? "day selected" : "day";
      if(schedules && schedules[i]) {
        days.push(<CalendarDay day={i} key={days.length} schedules={schedules[i]} classes={classes} clickCallback={() => selectDay(i)}/>);
      } else {
        days.push(<CalendarDay day={i} key={days.length} classes={classes} clickCallback={() => selectDay(i)}/>);
      }
    }

    const rows = [];
    let cellsInRow = [];

    for (let i = 0; i < days.length; i++) {
      cellsInRow.push(days[i]);

      if (cellsInRow.length === 7) {
        rows.push(<tr key={i}>{cellsInRow}</tr>);
        cellsInRow = [];
      }
    }

    if (cellsInRow.length > 0) {
      rows.push(<tr key={rows.length}>{cellsInRow}</tr>);
    }

    return(
      <table className="calendar">
        <thead>
          <tr>
            <th>Sunday</th>
            <th>Monday</th>
            <th>Tuesday</th>
            <th>Wednesday</th>
            <th>Thursday</th>
            <th>Friday</th>
            <th>Saturday</th>
          </tr>
        </thead>
        <tbody>{ rows }</tbody>
      </table>
    )
  }

  return (
    <section className='calendar'>
      { generateCalendar(year, month) }
      { showDayEditor &&
        <DialogBox closeDialog={closeDialog} component={<CalendarDayEditor calendarSid={calendarSid} month={month} year={year} day={selectedDay} />} />
      }
    </section>
  );
}
import axios from 'axios'
import { config } from '../../config';
import Seat from '../../types/Seat';
import Appointment from '../../types/Appointment';

export const getSeatsForProctorLocation = async (proctorLocationSid: string) : Promise<Seat[]> => {
  const response = await axios.get(`${config.apiUrl}/seat/${proctorLocationSid}`);
  return response.data;
}

export const createSeat = async (proctorLocationSid: string) : Promise<string> => {
  const response = await axios.post(`${config.apiUrl}/seat/create`, {
    proctorLocationSid: proctorLocationSid,
    name: "New Seat"
  });
  return response.data;
}

export const deleteSeat = async (seatSid: string) : Promise<number> => {
  const response = await axios.delete(`${config.apiUrl}/seat/${seatSid}`);
  return response.data;
}

export const getAppointmentsForSeat = async (seatSid: string, date: Date) : Promise<Appointment[]> => {
  const response = await axios.get(`${config.apiUrl}/appointment/seat/${seatSid}?date=${date.toISOString()}`);
  return response.data;
}

export const updateSeat = async (seat: Seat) : Promise<number> => {
  const response = await axios.put(`${config.apiUrl}/seat/${seat.sid}`, {
    name: seat.name,
    proctorLocationSid: seat.proctorLocationSid
  });
  return response.data;
}
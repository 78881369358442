import CloseButton from '../CloseButton';
import './index.css';

type Props = {
  closeDialog: () => void;
  component: JSX.Element
}

export default function DialogBox(props: Props) {
  const { closeDialog, component } = props;

  return (
    <section className="dialog-wrapper">
      <div className="dialog">
        <CloseButton closeFunction={closeDialog} />
        { component }
      </div>
    </section>
  )
}
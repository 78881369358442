import axios from 'axios';

import { config } from '../../config';

interface BulkScheduleCreateRequest {
  calendarSid: string;
      schedules: {
        "0": ScheduleRequest[];
        "1": ScheduleRequest[];
        "2": ScheduleRequest[];
        "3": ScheduleRequest[];
        "4": ScheduleRequest[];
        "5": ScheduleRequest[];
        "6": ScheduleRequest[];
      },
      startDate: string;
      endDate: string;
}

interface ScheduleRequest {
  capacity: number;
  appointmentIntervalMinutes: number;
  bufferAfterAppointmentMinutes: number;
  startTime: string;
  endTime: string;
}

export const bulkCreateSchedules = async (request: BulkScheduleCreateRequest) : Promise<boolean> => {
  const response = await axios.post(`${config.apiUrl}/schedule/create/bulk`, request);
  return response.status === 200;
}
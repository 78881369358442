import './index.css';

type Props = {
  closeFunction: () => void;
}

export default function CloseButton(props: Props) {
  const { closeFunction } = props;
  
  return (
    <div className="close-button" onClick={closeFunction}>
      <div className="cross-1">
        <div className="cross-2"></div>
      </div>
    </div>
  )
}